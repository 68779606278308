.fc {
  --day-border-color: #33393F;
  --disabled-color: #363D44;
  --disabled-header-color: #5E6A75;
  --gray-0-color: #292E33;
  --gray-1-color: #212529;
  --gray-2-color: #1F2327;
  --secondary-color: #F9BD4E;
  --secondary-color-hover: #F8B131;
  --secondary-contrast-text: #000000de;
  --unsaved-color: #C4C4C4;
}

/* Toolbar */

.fc .fc-header-toolbar {
  border-bottom: 1px solid var(--day-border-color);
  padding-bottom: 10px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

/* Toolbar buttons */

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type {
  flex-grow: 1;
  margin-left: 0.625rem;
  order: 2;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  flex-shrink: 0;
  order: 1;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:last-child {
  flex-shrink: 0;
  order: 3;
}

.fc .fc-header-toolbar .fc-button-group {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button-group {
  border: 0;
}

.fc .fc-header-toolbar .fc-button-group .fc-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
}

.fc .fc-header-toolbar .fc-button {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fc .fc-header-toolbar .fc-button:focus {
  box-shadow: none;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button {
  padding: 0;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button:nth-child(2) {
  margin-left: 0.625rem;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button:last-child {
  border-radius: 5px;
  margin-left: 0.25rem;
  padding: 0.375rem;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button:not(:disabled):hover {
  color: var(--secondary-color);
  background-color: transparent;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button:last-child:not(:disabled):hover {
  background-color: var(--secondary-color-hover);
  color: var(--secondary-contrast-text);
}

.fc .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fc .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button {
  background-color: var(--gray-0-color);
  color: white;
  padding: 0.375rem;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button.fc-button-active,
.fc .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button:hover {
  background-color: var(--secondary-color-hover);
  color: var(--secondary-contrast-text);
}

.fc .fc-header-toolbar .fc-button-group .fc-button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fc .fc-header-toolbar .fc-button-group .fc-button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Title */

.fc .fc-toolbar-title {
  font-size: 0.75rem;
}

.fc .fc-toolbar-title::first-letter {
  text-transform: uppercase;
}

/* Calendar */

.fc .fc-scrollgrid-section-sticky > * {
  background-color: var(--gray-2-color);
}

.fc .fc-scrollgrid-section-body .fc-scroller-harness {
  background-color: var(--gray-0-color);
  border-radius: 10px;
}

.fc .fc-day .fc-daygrid-day-frame {
  padding-top: 0.25rem;
}

/* Session calendar */

.fc-session-calendar .fc-day .fc-daygrid-day-frame {
  cursor: pointer;
}

.fc table {
  font-size: 0.75rem;
}

/* Month view */

.fc .fc-scrollgrid,
.fc.fc-theme-standard .fc-scrollgrid,
.fc.fc-theme-standard td,
.fc.fc-theme-standard th {
  border: 0;
}

.fc th {
  border: 0;
}

.fc td {
  border: 0;
}

.fc .fc-col-header-cell {
  height: 55px;
  text-transform: uppercase;
  vertical-align: middle;
}

.fc .fc-scrollgrid-sync-table {
  border-collapse: separate;
}

.fc .fc-daygrid-event-harness {
  margin-bottom: 0.5rem;
}

.fc .fc-scrollgrid-sync-table tr:last-of-type .fc-daygrid-day {
  border-bottom: 0;
}

.fc .fc-daygrid-day:last-of-type {
  border-right: 0;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  padding-left: 1.125rem;
}

.fc .fc-daygrid-day-number {
  font-size: 0.75rem;
  font-weight: 500;
  padding-right: 0;
  padding-bottom: 0.5rem;
  padding-left: 0;
}

.fc-administrator-view .fc .fc-daygrid-dot-event:hover,
.fc-trainer-view .fc .fc-daygrid-dot-event:hover {
  background: transparent;
}

.fc .fc-daygrid-day .fc-highlight{
  background: var(--gray-1-color);
}

/* Week view */

.fc .fc-col-header-cell.fc-day-today {
  color: var(--secondary-color);
}

.fc .fc-col-header-cell.fc-day-past {
  color: var(--disabled-header-color);
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
}

.fc .fc-day-past.fc-timegrid-col .fc-timegrid-col-frame {
  opacity: 0.5;
}

.fc .fc-timegrid-slot {
  cursor: pointer;
  height: 32px;
}

/* Week view event */

.fc .fc-timegrid-slot-label-frame {
  font-size: 0.75rem;
  text-align: left;
}

.fc .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
  padding: 0.5rem;
}

.fc .fc-timegrid-col .fc-highlight {
  display: none;
}

.fc .fc-timegrid-more-link {
  border: 1px solid var(--secondary-color);
  background-color: var(--gray-2-color);
  box-shadow: none;
}

.fc .fc-timegrid-more-link-inner {
  padding: 0.25rem;
}

/* Availability week view event */

.fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror,
.fc-availability-calendar .fc .fc-timegrid-event.fc-availability {
  align-items: center;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  display: flex;
  height: calc(100% - 8px);
  justify-content: center;
  margin: 0.1875rem 0 0 0;
  padding: 0;
}

.fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror {
  background-color: var(--unsaved-color);
}

.fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror .fc-event-main,
.fc-availability-calendar .fc .fc-timegrid-event.fc-availability .fc-event-main {
  height: 100%;
  padding: 0;
  width: 100%;
}

.fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror .fc-event-time {
  align-self: flex-start;
  font-size: 0.8125rem;
  font-weight: 500;
  text-align: center;
  white-space: normal;
  width: 100%;
}

.fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror .fc-event-time-text,
.fc-availability-calendar .fc .fc-timegrid-event.fc-availability-temporary .fc-event-time-text {
  color: var(--gray-2-color);
}

/* Session week view event */

.fc-session-calendar .fc .fc-timegrid-event {
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
}

/* Popover */

.fc .fc-popover {
  background: var(--gray-1-color);
  border: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.fc .fc-popover-header {
  background: var(--secondary-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--gray-2-color);
  font-weight: 500;
}

.fc .fc-popover-close {
  font-weight: 500;
  opacity: 1;
}

.fc .fc-popover .fc-daygrid-event-harness {
  margin-bottom: 0.5rem;
}

.fc .fc-popover .fc-daygrid-event-harness:last-child {
  margin-bottom: 0;
}

/* Media queries */

/* Small devices */

@media screen and (min-width: 600px) {
  .fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type {
    margin-left: 1.25rem;
  }

  .fc .fc-header-toolbar .fc-button-group .fc-button {
    font-size: 0.875rem;
    height: 36.5px;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button:last-child {
    margin-left: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button {
    padding: 0 0.4875rem;
  }

  .fc .fc-toolbar-title {
    font-size: 1.125rem;
  }

  .fc .fc-timegrid-slot-label-frame {
    font-size: 0.875rem;
  }
}

/* Medium devices */

@media screen and (min-width: 900px) {
  .fc .fc-header-toolbar {
    padding-bottom: 18px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 14px;
  }

  .fc .fc-toolbar-title {
    font-size: 1.25rem;
  }

  .fc table {
    font-size: 0.875rem;
  }

  .fc .fc-daygrid-day-number {
    font-size: 0.875rem;
  }

  .fc .fc-scrollgrid-section-body .fc-scroller-harness {
    border-radius: 8px;
  }

  .fc-availability-calendar .fc .fc-timegrid-event.fc-event-mirror,
  .fc-availability-calendar .fc .fc-timegrid-event.fc-availability {
    border-radius: 4px;
  }
}

/* Desktop */

@media screen and (min-width: 1200px) {
  .fc .fc-header-toolbar {
    padding-bottom: 28px;
  }

  .fc .fc-toolbar-title {
    font-size: 2.125rem;
  }

  .fc .fc-header-toolbar .fc-button-group .fc-button {
    font-size: 1rem;
  }

  .fc table {
    font-size: 1rem;
  }

  .fc .fc-day .fc-daygrid-day-frame {
    padding-top: 0.5rem;
    padding-left: 0.78125rem;
    padding-right: 0.625rem;
  }

  .fc .fc-daygrid-day-top {
    padding-left: 1.5rem;
  }
}
