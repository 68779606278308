.MuiDropzone {
  --bg-color: #16191C;
  --error-color: #841010;
  --gray-0-color: #292E33;
  --secondary-color: #f8b131;

  background-color: var(--gray-0-color) !important;
  border: none !important;
  border-radius: 0.625rem !important;
  min-height: auto !important;
  padding: 1.25rem 0.625rem;
}

.MuiDropzone .MuiDropzone-Paragraph {
  font-size: 1rem;
  margin-top: 0;
}

.MuiDropzone + span {
  display: block;
  margin-top: 0.5rem;
}

.MuiDropzone-Preview {
  --error-color: #841010;
  justify-content: center;
  margin-top: 0.625rem;
}

.MuiDropzone-Preview button {
  background-color: var(--error-color);
  color: white;
}

.MuiDropzone-Preview button:hover {
  background-color: var(--error-color);
  color: white;
  opacity: 1;
}

.MuiDropzone-Preview button:hover svg {
  opacity: 1;
}
